// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { lightTheme, darkTheme } from './theme'; // Named imports
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

// Choose the default theme (e.g., lightTheme)
const currentTheme = lightTheme;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={currentTheme}>
    <CssBaseline /> {/* Resets CSS and applies the theme's styles */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
);
