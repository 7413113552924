// src/components/SearchHistory.js
import React from 'react';
import { List, ListItem, ListItemText, Button, Box, Typography } from '@mui/material';

const SearchHistory = ({ history, onSelect, onClear }) => {
    console.log('Rendering SearchHistory with:', history); // Debugging

    if (!history || history.length === 0) {
        return (
            <Typography variant="body2" color="textSecondary">
                No search history available.
            </Typography>
        );
    }

    return (
        <Box>
            <List>
                {history.map((item, index) => (
                    <ListItem button key={index} onClick={() => onSelect(item)}>
                        <ListItemText primary={item.question} />
                    </ListItem>
                ))}
            </List>
            <Button variant="outlined" color="secondary" onClick={onClear}>
                Clear History
            </Button>
        </Box>
    );
};

export default SearchHistory;
