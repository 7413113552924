// src/App.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Switch, FormControlLabel } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import SearchBar from './components/SearchBar';
import AnswerDisplay from './components/AnswerDisplay';
import SearchHistory from './components/SearchHistory';
import AnswerRoute from './components/AnswerRoute';
import { lightTheme, darkTheme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';

const App = () => {
  const [answer, setAnswer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [history, setHistory] = useState(() => {
    const stored = localStorage.getItem('searchHistory');
    return stored ? JSON.parse(stored) : [];
  });
  const [loading, setLoading] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check localStorage or system preference
    const storedTheme = localStorage.getItem('isDarkMode');
    if (storedTheme !== null) {
      return storedTheme === 'true';
    }
    // Default to system preference
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Listen for system theme changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      if (localStorage.getItem('isDarkMode') === null) {
        setIsDarkMode(e.matches);
      }
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const saveHistory = (item) => {
    const existingIndex = history.findIndex(
      (historyItem) => historyItem.question === item.question
    );

    let updatedHistory;
    if (existingIndex !== -1) {
      // Remove the existing item and add the new one at the top
      updatedHistory = [
        item,
        ...history.slice(0, existingIndex),
        ...history.slice(existingIndex + 1, 10),
      ];
    } else {
      // Add as new item
      updatedHistory = [item, ...history.slice(0, 9)];
    }

    setHistory(updatedHistory);
    localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
    console.log('Updated History:', updatedHistory); // Debugging
  };

  const handleSearch = (question) => {
    setLoading(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json, text/plain, */*',
      },
    };

    axios
      .post('https://kwq.ngrok.app/search', { question }, config)
      .then((response) => {
        console.log('API Response:', response.data); // Debugging
        const answerData = response.data.answer; // Extract 'answer' field
        console.log('Extracted Answer:', answerData); // Debugging
        setAnswer(answerData);
        setErrorMessage(''); // Clear any previous error messages
        const item = { question, answer: answerData };
        saveHistory(item);
        setLoading(false);
        navigate(`/answer/${encodeURIComponent(question)}`);
      })
      .catch((error) => {
        console.error('Error fetching the answer:', error);
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage('An unexpected error occurred. Please try again later.');
        }
        setLoading(false);
      });
  };

  const handleHistorySelect = (item) => {
    setAnswer(item.answer);
    navigate(`/answer/${encodeURIComponent(item.question)}`);
  };

  const handleClearHistory = () => {
    setHistory([]);
    localStorage.removeItem('searchHistory');
    console.log('Search history cleared.');
  };

  const handleThemeToggle = (event) => {
    setIsDarkMode(event.target.checked);
    localStorage.setItem('isDarkMode', event.target.checked);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline /> {/* Applies global styles based on the theme */}
      <Container maxWidth="md">
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ fontSize: { xs: '2rem', md: '3rem' } }}
          >
            Kwq Question Answering
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isDarkMode}
                onChange={handleThemeToggle}
                name="themeToggle"
                color="primary"
              />
            }
            label={isDarkMode ? 'Dark Mode' : 'Light Mode'}
          />
        </Box>
        <SearchBar onSearch={handleSearch} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                {loading ? (
                  <Box mt={2}>
                    <Typography variant="body1">Loading...</Typography>
                  </Box>
                ) : (
                  <AnswerDisplay answer={answer} errorMessage={errorMessage} />
                )}
                <Typography variant="h5" sx={{ mt: 4 }}>
                  Search History
                </Typography>
                <SearchHistory
                  history={history}
                  onSelect={handleHistorySelect}
                  onClear={handleClearHistory}
                />
              </>
            }
          />
          <Route
            path="/answer/:question"
            element={
              <AnswerRoute
                answer={answer}
                setAnswer={setAnswer}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            }
          />
        </Routes>
      </Container>
    </ThemeProvider>
  );
};

export default App;
