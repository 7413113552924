// src/components/SearchBar.js
import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';

const SearchBar = ({ onSearch }) => {
    const [question, setQuestion] = useState('');

    const handleSearch = () => {
        if (question.trim() !== '') {
            onSearch(question);
            setQuestion('');
        }
    };

    return (
        <Box display="flex" alignItems="center" mt={2} mb={2}>
            <TextField
                fullWidth
                variant="outlined"
                label="Ask a question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            />
            <Button
                variant="contained"
                onClick={handleSearch}
                sx={{
                    ml: 2,
                    color: '#000000', // Black text
                    backgroundColor: '#ffffff', // White background
                    '&:hover': {
                        backgroundColor: '#e0e0e0', // Light gray on hover
                    },
                }}
            >
                Search
            </Button>
        </Box>
    );
};

export default SearchBar;
