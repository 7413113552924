// src/components/AnswerRoute.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AnswerDisplay from './AnswerDisplay';
import axios from 'axios';
import { Box, Typography } from '@mui/material';

const AnswerRoute = ({ answer, setAnswer, errorMessage, setErrorMessage }) => {
    const { question } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json, text/plain, */*',
            },
        };

        axios
            .post(
                'https://kwq.ngrok.app/search',
                { question: decodeURIComponent(question) },
                config
            )
            .then((response) => {
                console.log('API Response (AnswerRoute):', response.data); // Debugging
                const answerData = response.data.answer; // Extract 'answer' field
                console.log('Extracted Answer (AnswerRoute):', answerData); // Debugging
                setAnswer(answerData);
                setErrorMessage(''); // Clear any previous error messages
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching the answer (AnswerRoute):', error);
                if (error.response && error.response.data && error.response.data.error) {
                    setErrorMessage(error.response.data.error);
                } else {
                    setErrorMessage('An unexpected error occurred. Please try again later.');
                }
                setLoading(false);
            });
    }, [question, setAnswer, setErrorMessage]);

    if (loading) {
        return (
            <Box mt={2}>
                <Typography variant="body1">Loading...</Typography>
            </Box>
        );
    }

    return <AnswerDisplay answer={answer} errorMessage={errorMessage} />;
};

export default AnswerRoute;
