// src/theme.js
import { createTheme } from '@mui/material/styles';

// Define the light theme
const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1976d2', // MUI default primary color
        },
        background: {
            default: '#f5f5f5', // Light gray background
            paper: '#ffffff',    // White paper background
        },
        text: {
            primary: '#000000',  // Black text
            secondary: '#555555', // Dark gray text
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#f5f5f5',
                    color: '#000000',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        color: '#000000',
                    },
                    '& .MuiInputLabel-root': {
                        color: '#000000',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                            borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#1976d2',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    backgroundColor: '#1976d2',
                    '&:hover': {
                        backgroundColor: '#115293',
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: '#000000',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    color: '#000000',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#000000',
                },
            },
        },
    },
});

// Define the dark theme
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ffffff', // White primary color for contrast
        },
        background: {
            default: '#121212', // Very dark background
            paper: '#1d1d1d',    // Dark paper background
        },
        text: {
            primary: '#ffffff',  // White text
            secondary: '#bbbbbb', // Light gray text
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#121212',
                    color: '#ffffff',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        color: '#ffffff',
                    },
                    '& .MuiInputLabel-root': {
                        color: '#ffffff',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#ffffff',
                        },
                        '&:hover fieldset': {
                            borderColor: '#ffffff',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#ffffff',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#000000',
                    backgroundColor: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#e0e0e0',
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: '#ffffff',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
    },
});

export { lightTheme, darkTheme };
