// src/components/AnswerDisplay.js
import React from 'react';
import { Typography, Box } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const AnswerDisplay = ({ answer, errorMessage }) => {
    console.log('AnswerDisplay - answer:', answer); // Debugging

    if (errorMessage) {
        // Display the error message
        return (
            <Box mt={2}>
                <Typography variant="body1" color="error">
                    {errorMessage}
                </Typography>
            </Box>
        );
    }

    if (!answer) {
        // No search has been performed yet; don't display anything
        return null;
    }

    if (typeof answer !== 'string') {
        // Handle cases where answer is not a string
        return (
            <Box mt={2}>
                <Typography variant="body1" color="error">
                    Invalid answer format.
                </Typography>
            </Box>
        );
    }

    // Function to parse and format code blocks
    const formatAnswer = (text) => {
        const codeBlockRegex = /```(.*?)\n([\s\S]*?)```/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = codeBlockRegex.exec(text)) !== null) {
            const [fullMatch, language, code] = match;
            const index = match.index;

            if (index > lastIndex) {
                parts.push({
                    type: 'text',
                    content: text.substring(lastIndex, index),
                });
            }

            parts.push({
                type: 'code',
                language: language || 'text',
                content: code,
            });

            lastIndex = index + fullMatch.length;
        }

        if (lastIndex < text.length) {
            parts.push({
                type: 'text',
                content: text.substring(lastIndex),
            });
        }

        return parts;
    };

    const formattedParts = formatAnswer(answer);

    return (
        <Box mt={2}>
            {formattedParts.map((part, index) =>
                part.type === 'code' ? (
                    <SyntaxHighlighter
                        key={index}
                        language={part.language}
                        style={oneDark}
                        showLineNumbers
                    >
                        {part.content}
                    </SyntaxHighlighter>
                ) : (
                    <Typography key={index} variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                        {part.content}
                    </Typography>
                )
            )}
        </Box>
    );
};

export default AnswerDisplay;
